class QuickNavigation extends lm.Composant {
  /**
   * @constructor
   * @param {String} id
   */
  constructor(id) {
    super(id);
    const jsMoreCat = this.target.querySelector('.js-more-cat');
    if (jsMoreCat) {
      jsMoreCat.addEventListener('click', e => {
        this.displayHandler(e.target);
      }, false);
    }
  }

  /**
   * @param {HTMLElement} element
   */
  displayHandler(element) {
    const isOpen = element.closest('.o-nav__list').classList.contains('o-nav__list--isopen');
    element.closest('.o-nav__list').classList.toggle('o-nav__list--isopen', !isOpen);
    const ariaExpanded = !isOpen ? 'true' : 'false';
    element.setAttribute('aria-expanded', ariaExpanded);
  }
}
lm.DOMReady(() => {
  new QuickNavigation('quicknavigation');
});